<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1200px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Advanced filter</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="[
                        { id: '0', label: 'WFM Compliant ' },
                        { id: '1', label: 'PM Compliant' },
                        { id: '2', label: 'CM Compliant' },
                        { id: '3', label: 'WFM Non Compliant ' },
                        { id: '4', label: 'PM Non Compliant' },
                        { id: '5', label: 'CM Non Compliant' },
                      ]"
                      v-model="compliant"
                      item-text="label"
                      item-value="id"
                      label="Compliance"
                      dense
                      multiple
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="components_list"
                      v-model="components"
                      item-text="label"
                      item-value="id"
                      label="Failed Barriere"
                      dense
                      multiple
                      clearable
                    ></v-select>
                  </v-col>
                  <v-row>
                    <v-col cols="12" sm="3" md="3">
                      <v-select
                        :items="[
                          { id: '1', label: 'Good Cement', color: 'green' },
                          {
                            id: '2',
                            label: 'Moderate Cement',
                            color: 'orange',
                          },
                          { id: '3', label: 'Poor Cement', color: 'red' },
                          { id: '4', label: 'Free Pipe', color: 'gray' },
                        ]"
                        v-model="cements"
                        item-value="id"
                        item-text="label"
                        item-color="color"
                        label="Cement Evaluation"
                        dense
                        multiple
                        @change="cem_clear"
                        clearable
                      >
                        <template #selection="{ item }">
                          <v-chip small :color="item.color">{{
                            item.label
                          }}</v-chip>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-select
                        :items="strings"
                        v-model="strings_cem"
                        item-value="id"
                        item-text="item"
                        label="String"
                        multiple
                        dense
                        :disabled="cements.length == 0"
                        clearable
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-autocomplete
                        :items="formations"
                        v-model="cement_formations"
                        item-value="id"
                        item-text="formationname"
                        label="Formations"
                        multiple
                        dense
                        :disabled="cements.length == 0"
                        clearable
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3" md="3">
                      <v-select
                        :items="[
                          { id: '1', label: 'Equal' },
                          { id: '2', label: 'Greater than' },
                          { id: '3', label: 'Greater or equal than' },
                          { id: '4', label: 'Less than' },
                          { id: '5', label: 'Less or equal than' },
                          { id: '6', label: 'Between' },
                        ]"
                        v-model="opr"
                        item-value="id"
                        item-text="label"
                        item-color="color"
                        label="Corrosion"
                        dense
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        type="number"
                        dense
                        v-model.number="cor1"
                        :label="'Value (%)'"
                        :rules="[
                          (v) =>
                            !v ||
                            (v > 0 && v <= 100) ||
                            'Value error (Between 0 and 100) ',
                        ]"
                        @focus="$event.target.select()"
                        hide-spin-buttons
                        clearable
                        @change="cor_clear"
                        @input="cor_clear"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" v-if="opr == 6">
                      <v-text-field
                        autocomplete="off"
                        type="number"
                        dense
                        v-model.number="cor2"
                        :label="'And Value(%)'"
                        :rules="[
                          (v) => !!v || cor1 == null || 'Value 2 required ',
                          (v) =>
                            !v ||
                            (v > 0 && v <= 100) ||
                            'Value error (Between 0 and 100) ',
                          (v) => !v || v >= cor1 || 'Value error (>value1) ',
                        ]"
                        @focus="$event.target.select()"
                        :disabled="cor1 == null"
                        hide-spin-buttons
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-select
                        :items="strings"
                        v-model="strings_cor"
                        item-value="id"
                        item-text="item"
                        label="String"
                        multiple
                        :disabled="cor1 == null"
                        dense
                        clearable
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-autocomplete
                        :items="formations"
                        v-model="corrosion_formations"
                        item-value="id"
                        item-text="formationname"
                        label="Formation"
                        multiple
                        dense
                        :disabled="cor1 == null"
                        clearable
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ml-2"
              color="indigo"
              @click="filtrer"
              :disabled="
                components.length == 0 &&
                compliant.length == 0 &&
                cements.length == 0 &&
                cor1 == null
              "
            >
              <v-icon> mdi-filter </v-icon>
              Filter
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn color="indigo" @click="close" class="ml-2"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "filterform",
  components: {},
  props: {
    showForm: Boolean,
    components_list: Array,
    strings: Array,
    formations: Array,
    fluids: Array,
  },
  data: () => ({
    afficher: false,
    valid: true,
    compliant: [],
    components: [],
    cements: [],
    strings_cor: [],
    strings_cem: [],
    corrosion_formations: [],
    cement_formations: [],
    opr: "1",
    cor1: null,
    cor2: null,
    pff: false,
    tubular_id: null,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {},
  watch: {},

  created() {
    this.corrosion_formations = this.formations;
    this.cements_formations = this.formations;
  },

  methods: {
    cor_clear() {
      if (this.cor1 == null || this.cor1 == "") {
        this.corrosion_formations = [];
        this.strings_cor = [];
        this.cor2 = null;
      }
    },
    cem_clear() {
      if (this.cements.length == 0) {
        this.cement_formations = [];
        this.strings_cem = [];
      }
    },
    close() {
      this.$emit("close");
    },

    filtrer() {
      if (this.$refs.form.validate()) {
        let cor = [];

        let cm_j, cr_j;

        if (this.cements.length > 0) {
          cm_j = { cements: this.cements };
          if (this.strings_cem.length > 0) {
            cm_j.tubulars = this.strings_cem;
          }
          if (this.cement_formations.length > 0) {
            cm_j.formations = this.cement_formations;
          }
        }
        if (this.cor1) cor.push(this.cor1);
        if (this.opr == 6) cor.push(this.cor2);
        if (cor.length > 0) {
          cr_j = { opr: this.opr, cor1: this.cor1, cor2: this.cor2 };
          if (this.strings_cor.length > 0) {
            cr_j.tubulars = this.strings_cor;
          }
          if (this.corrosion_formations.length > 0) {
            cr_j.formations = this.corrosion_formations;
          }
        }
        let filter_j = { corrosion: cr_j, cement: cm_j };
        this.$emit("filter", this.components, this.compliant, filter_j);
      }
    },
  },
};
</script>
